import {AiOutlineShoppingCart} from "react-icons/ai";
import {
    FaDesktop,
    FaUserCog,
    GiCutDiamond,
    MdBeachAccess,
    FaRssSquare,
    MdFastfood,
    BsFillDoorOpenFill,
    FaShuttleVan,
    FaCalendar,
    FaChartLine,
    FaCarSide,
    FaSleigh
} from "react-icons/all";
import Footer from "../Footer";
import React, {useEffect, useState} from "react";
import Header from "../Header";
import Card from "../Card";
import {config} from "../../config";
import {User} from "../../model/user.model";

const cards = [
    {
        name: "Time OFF",
        color: "#fc5c65",
        textColor: "white",
        link: `${config.DOMAIN_URL}/timeoff-web/preview`,
        icon: <MdBeachAccess/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Orders",
        color: "#f7b731",
        textColor: "white",
        link: `${config.DOMAIN_URL}/orders-web/`,
        icon: <AiOutlineShoppingCart/>,
        devOnly: false,
        accessibleRoles: []
    },
    // {
    //     name: "Business Travel",
    //     color: "#26de81",
    //     textColor: "white",
    //     link: `${config.DOMAIN_URL}/btravel-web/`,
    //     icon: <IoAirplane/>,
    //     devOnly: false,
    //     accessibleRoles: []
    // },
    {
        name: "Reservations",
        color: "#4b7bec",
        textColor: "white",
        link: `${config.DOMAIN_URL}/reservations-web/dashboard`,
        icon: <FaDesktop/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Majetok",
        color: "#778ca3",
        textColor: "white",
        link: `${config.DOMAIN_URL}/majetok-web/`,
        icon: <GiCutDiamond/>,
        devOnly: false,
        accessibleRoles: ['ROLE_MAJETOK.ADMINISTRATOR', 'ROLE_MAJETOK.USER']
    },
    {
        name: "ProMan",
        color: "#a55eea",
        textColor: "white",
        link: `${config.DOMAIN_URL}/proman-web/`,
        icon: <FaUserCog/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "TechSpot",
        color: "#0fb9b1",
        textColor: "white",
        link: `${config.DOMAIN_URL}/tspot-web/`,
        icon: <FaRssSquare/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "E-buffet",
        color: "#fa8231",
        textColor: "white",
        link: 'https://e-bufet.web.app/',
        icon: <MdFastfood/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Door opener",
        color: "#8854d0",
        textColor: "white",
        link: 'http://dooropener.local/',
        icon: <BsFillDoorOpenFill/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Rent a van",
        color: "#4b6584",
        textColor: "white",
        link: 'https://teams.microsoft.com/l/channel/19%3a6af0c4e9941c459c9963cab28666f443%40thread.skype/Rent%2520a%2520Van?groupId=643c65f6-8494-47ce-a287-f165ccbda9f1&tenantId=5f167645-e36b-47eb-bdbe-4bdc01e5dcb4',
        icon: <FaShuttleVan/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Meeting rooms",
        color: "#20bf6b",
        textColor: "white",
        link: `${config.DOMAIN_URL}/mrooms-web/`,
        icon: <FaCalendar/>,
        devOnly: false,
        accessibleRoles: []
    },
    {
        name: "Grafana",
        color: "#45aaf2",
        textColor: "white",
        link: `${config.DOMAIN_URL}/grafana/`,
        icon: <FaDesktop />,
        devOnly: true,
        accessibleRoles: []
    },
    {
        name: "Prometheus",
        color: "#3867d6",
        textColor: "white",
        link: `${config.DOMAIN_URL}/prometheus/graph`,
        icon: <FaChartLine />,
        devOnly: true,
        accessibleRoles: []
    },
    {
        name: "Cars",
        color: "#a5b1c2",
        textColor: "white",
        link: `${config.DOMAIN_URL}/cars-web/`,
        icon: <FaCarSide />,
        devOnly: true,
        accessibleRoles: []
    },
    {
        name: "Vianočný večierok",
        color: "#FC5C65FF",
        textColor: "white",
        link: `https://e-minigames.web.app`,
        icon: <FaSleigh />,
        devOnly: true,
        accessibleRoles: []
    }
]

const Dashboard = () => {
    const [user, setUser] = useState<User>({
        displayName: "",
        email: "",
        roles: []
    });
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('authenticatedUser') as string))
    }, [])

    const checkDate = () => {
        const targetDate = new Date(2024, 11, 12); // Month is 0-indexed, so 11 = December
        const today = new Date();
        return (
            today.getFullYear() === targetDate.getFullYear() &&
            today.getMonth() === targetDate.getMonth() &&
            today.getDate() === targetDate.getDate()
        )
    };

    let cardsToShow = [...cards];
    if (!config.IS_DEV) {
        cardsToShow = cardsToShow.filter(card => (!card.devOnly || (card.devOnly && card.name === "Vianočný večierok" && checkDate())))
            .filter(card => card.accessibleRoles.length === 0 ||
                card.accessibleRoles.some(accessibleRole => user.roles.includes(accessibleRole)));
    }
    return (
        <div className="App">
            <Header/>
            <div className="container mx-auto pt-16 xl:pl-4 xl:pr-4">
                <div className="home-page ">
                    {cardsToShow.map(el => (
                            <Card name={el.name} color={el.color} icon={el.icon} textColor={el.textColor} link={el.link}
                                  key={el.name}/>
                        )
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Dashboard;
